.nav {
  position: relative;
  flex: 1;
  width: 100%;
  height: 55px;
  background-color: white;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #cccccc;
}
.nav-arrow-icon {
  position: relative;
  top: 5%;
  padding-right: 10px;
}
.nav-back-to-main-site {
  position: relative;
  left: 5%;
  top: 25%;
  color: black;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 600px) {
  .nav-logo {
    height: 60%;
    position: absolute;
    top: 20%;
    right: 15%;
    margin: auto;
  }
}
@media only screen and (min-width: 601px) {
  .nav-logo {
    height: 60%;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.header-pathways-logo {
  width: 60%;
}
.header-bottom {
  min-height: 200px;
  background-color: white;

  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #cccccc;

  text-align: center;
  max-width: 90ch;
  margin: 0 auto 20px auto;
  padding: 20px;
}
.header-pathways-intro {
  width: 80%;
  margin: 0 auto;
  text-align: start;
}
