.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-container {
  display: flex;
  position: absolute;
  top: 6%;
  bottom: 6%;
  left: 10%;
  right: 10%;
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 9998;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
}
.modal-overlay,
.modal-container {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.modal-container.ReactModal__Content--after-open,
.modal-overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}
.modal-container.ReactModal__Content--before-close,
.modal-overlay.ReactModal__Overlay--before-close {
  opacity: 0;
  transition-timing-function: ease-in;
}
@media only screen and (max-width: 600px) {
  /* Increase the modal size on smaller devices */
  .modal-container {
    top: 5%;
    bottom: 5%;
    left: 5%;
    right: 5%;
  }
}
/* Allow content to overflow, without affecting positioning of close button. */
.modal-content {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}
.modal-instructor {
  display: flex;
  height: 80%;
  flex-direction: row;
  text-align: left;
  min-width: 250px;
}
.modal-instructor-text {
  margin: auto 0;
}
.modal-close {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  z-index: 9999;
  border: none;
  background-color: transparent;
}
.modal-close:active {
  outline: none;
}
.modal-image {
  height: 80%;
  max-width: 100%;
  margin: auto 20px auto 0;
}
.modal-video {
  aspect-ratio: 16 / 9;
  margin: auto 0;
}
.modal-metadata-container {
  width: 80%;
  margin: 10px auto;
}
.modal-metadata {
  text-align: left;
}
.modal-bio {
  width: 80%;
  margin: 20px auto;
  text-align: left;
  font-size: 90%;
}
.modal-bottom-header {
  margin: 12px auto 0 auto;
}
@media only screen and (max-width: 600px) {
  .modal-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-bottom: 2px #ccc solid;
  }
  .modal-left {
    padding: 0;
    margin: 0 auto;
  }
  .modal-right {
    margin: 20px 12px;
  }
  .modal-bio {
    font-size: 100%; /* Larger font size on mobile */
  }
  .modal-bottom {
    display: flex;
    flex: 0;
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
  }
  .modal-instructor {
    height: 150px;
  }
  .modal-image {
    height: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }
}
/*
 * Default to normal document positioning for modal (top to bottom) on smaller viewports
 * and only switch to flexbox on larger viewports.
 */
@media only screen and (min-width: 601px) {
  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .modal-main {
    display: flex;
    flex: 1;
    flex-direction: row;
    border-bottom: 2px #ccc solid;
  }
  .modal-left {
    flex: 50 1 auto;
    width: 50%;
  }
  .modal-right {
    flex: 50 1 auto;
    width: 50%;
    padding-right: 24px;
  }
  .modal-left,
  .modal-right {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal-bottom {
    display: flex;
    flex: 0;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-evenly;
    padding: auto 0;
    margin: 0 auto;
  }
  .modal-instructor {
    width: 30%;
    height: 150px;
    margin-top: 20px;
  }
}
