.container {
  height: 100%;
  background-color: white;
}

.canvas {
  max-width: 1056px;
  min-height: 200px;
  margin: auto;
}

.results {
  min-height: 100vh;
}

.results-text {
  font-style: italic;
  text-align: center;
}

.placeholder-text {
  font-style: italic;
  text-align: center;
}

.card-container {
  display: flex;
  flex: none;
  /* flex-wrap: wrap; */
  justify-content: center;
}

.footer {
  margin: 0 auto 24px auto;
  padding: 20px;
  max-width: 90ch;
  border-top: 1px #cccccc solid;
}

.footer-text {
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .card-container {
    flex-wrap: wrap;
  }
}
